import React, { useContext, useState } from 'react';
import { handleText } from '../utils/handle-text';
import { gameRequests } from '../requests/gameRequests';

// import { Actions } from 'react-native-router-flux';
export const BetContext = React.createContext({
  betStates: {
    selectedGames: [],
    gamesTypes: [],
    indexGamesType: {},
    programing: [],
    gameCode: '', // código da pule
    puleBarDate: {},
    specialBets: [],
    //Data e extração
    selectedDate: null,
    extracoes: [],
    jogos_salvo: {
      jogos: [],
      total: '',
    },
    tipojogos: [],
    views: [],
    valido: false,
    tipojogo: {},
    valor_fixo: 0,
    siglas: '',
    campos: [],
    campos_premio: {},
    botoesCheck: [],
    valores: [],
    isFlex: false,
    valor: '',
    valor2: '',
    //ViewGameScreenStates
    gameAlreadyEnd: false,
    loading: false,
    //BetPrintData
    aposta: null,
    // one input data
    oneInput: '',
    disableInput: false,
    games_list: [],
    gameToEdit: false,
    jogos_cache: [],
  },
  jogos_cache: {},
  shouldJBLimitUpdate: false,
  setShouldJBLimitUpdate: bool => false,
  setJogosCache: () => false,
  setBetStates: () => false,
  changeBetState: values => null,
  // Game Actions
  resetGames: () => false,
  repetBets: () => false,
  prepareGame: () => false,
  changeOneValueInput: (valor, pressKey) => false,
  handleAwardButton: labels => false,
  limparPremios: () => false,
  changeValueToPay: value => false,
  saveGame: () => false,
  changeAwardValue: (index, valor) => false,
  removeSavedGame: index => false,
  prepareToEditBet: () => false,
  deleteGameField: index => false,
  saveBet: () => false,
});

const { Provider } = BetContext;

export const useBetContext = () => useContext(BetContext);

export const BetProvider = ({ children }) => {
  const [betStates, setBetStates] = useState({});
  // const [apostaDocumentMutation ] = useMutation(ApostaDocument)
  const [jogos_cache, setJogosCache] = useState();
  const [shouldJBLimitUpdate, setShouldJBLimitUpdate] = useState();


  const changeBetState = values => {
    setBetStates(prev => ({ ...prev, ...values }));
  };

  const resetGames = () => {
    setBetStates(prev => {
      prev.jogos_salvo = {
        jogos: [],
        total: 0,
      };
      prev.tipoJogo = {};
      prev.tipoJogos = [];
      return prev;
    });
  };

  function changeOneValueInput(valor, pressKey) {
    valor = handleText.onlyNumber(valor);
    pressKey = pressKey;
    let tipojogo = betStates.tipojogo;
    //Verifica se o tamanho da string está no limite max
    if (
      valor.length == tipojogo.caracter_max_form ||
      (pressKey && valor.length >= tipojogo.caracter_min_form)
    ) {
      //Verica se aonde tem grupo se o grupo é válido
      if (tipojogo?.isGrupo && verificaGruposInvalidos(valor, tipojogo))
        return validaJogo({ ...betStates, oneInput: valor });
      //verifica se os valores estão corretos
      let valores = tipojogo.isntDividido
        ? valor
        : camposJogos(valor, tipojogo);
      if (betStates.games_list.length < tipojogo.qtd_jogosv2) {
        betStates.games_list.unshift(valores);
        if (betStates.games_list.length == tipojogo.qtd_jogosv2) {
          betStates.disableInput = true;
        }
      }
      return validaJogo({ ...betStates, oneInput: '' });
    } else return validaJogo({ ...betStates, oneInput: valor });
  }

  function prepareGame(saveGameWithoutResetFields = false) {
    let tipojogos = betStates.selectedGames;
    let tipojogo = tipojogos[tipojogos.length - 1]; // pega o ultimo tipo de
    let valores = [];

    let campos_min = parseInt(tipojogo.campos_min);
    let campos_max = parseInt(tipojogo.campos_max);
    let premios_max = parseInt(tipojogo.premio_fim);
    let campos_premio = {};

    for (let i = 0; i < premios_max; i++) {
      campos_premio[i + 1] = false;
    }

    let premio_fixo = tipojogo.premio_fixo;
    if (premio_fixo.length) {
      for (let i of premio_fixo) {
        campos_premio[i] = true;
      }
    }
    let botoesCheck = [];
    let gerarCampos = true;
    if (tipojogo.tipo == 'C') {
      gerarCampos = false;
      botoesCheck = [];
      let { range_ini, range_fim, caracter_max } = tipojogo;
      for (let i = Number(range_ini); i <= Number(range_fim); i++) {
        botoesCheck.push({
          value: handleText.zeroEsquerda(parseInt(caracter_max), i),
          status: false,
        });
      }
      campos_min = Number(tipojogo.campos_min);
      campos_max = Number(tipojogo.campos_max);
      premios_max = Number(tipojogo.premio_fim);
    }

    let campos = [];
    let isFlex = campos_max > campos_min;
    let siglas = tipojogos.map(value => value.sigla).join(' ');

    if (saveGameWithoutResetFields) {
      betStates.oneInput = '';
    } else {
      campos = [];
      betStates.oneInput = '';
      betStates.games_list = [];
      betStates.disableInput = false;
    }
    setJogosCache(null);
    return validaJogo({
      ...betStates,
      tipojogos,
      gameToEdit: false,
      tipojogo,
      siglas,
      isFlex,
      campos,
      campos_premio,
      valores,
      botoesCheck,
      views: [],
      valor2: '',
    });
  }

  function handleAwardButton(labels) {
    let alltrue = false;

    for (let i in betStates.campos_premio) {
      betStates.campos_premio[i] = false;
    }

    if (labels.length) {
      for (let i = parseInt(labels[0]); i <= parseInt(labels[1]); i++) {
        alltrue += betStates.campos_premio[i];
      }
    }

    if (labels.length) {
      for (let i = parseInt(labels[0]); i <= parseInt(labels[1]); i++) {
        betStates.campos_premio[i] = !alltrue;
      }
    }
    return validaJogo({
      ...betStates,
      campos_premio: { ...betStates.campos_premio },
    });
  }

  function limparPremios() {
    if (!betStates?.tipojogo?.premio_fixo.length) {
      console.log('ta limpando o');
      
      for (let i in betStates.campos_premio) {
        betStates.campos_premio[i] = false;
      }
    }

    betStates.oneInput = '';
    betStates.games_list = [];
    betStates.disableInput = false;

    return validaJogo({
      ...betStates,
      valor2: '',
      campos_premio: { ...betStates.campos_premio },
    });
  }

  let validaJogo = state => {
    state.valor = state.valor2;
    setBetStates({
      ...state,
      valido: isValido(state),
      oneInput: state.oneInput,
    });
  };

  function changeValueToPay(value) {
    validaJogo({ ...betStates, valor2: handleText.moneyBr(value) });
  }

  function saveGame() {
    betStates.games_list.map(game => {
      let games = game.split('-');
      games.map(g => {
        betStates.campos.push({
          valor: g,
          valor2: '',
          removivel: false,
        });
      });
    });
    verificaCotadas(betStates);
    let state = ajustaJogo(betStates, betStates);
    validaJogo(state);
  }

  function changeAwardValue(index, valor) {
    betStates.campos_premio[index] = !valor;
    return validaJogo({
      ...betStates,
      campos_premio: { ...betStates.campos_premio },
    });
  }

  function removeSavedGame(index) {
    betStates.jogos_salvo.jogos.splice(index, 1);
    betStates.jogos_salvo.total = 0;
    betStates.jogos_salvo.jogos.map(
      j => (betStates.jogos_salvo.total += j.valor)
    );
    betStates.jogos_salvo = { ...betStates.jogos_salvo };
    return validaJogo({ ...betStates });
  }

  function prepareToEditBet() {
    let tipojogo = betStates.gameToEdit.tipojogo;
    let tipojogos = betStates.gameToEdit.tipojogos;
    let campos = betStates.gameToEdit.campos;
    let valor = betStates.gameToEdit.valor;
    let campos_premio = betStates.gameToEdit.premios;
    let premio_fixo = tipojogo.premio_fixo;
    betStates.games_list.length = 0;
    if (premio_fixo.length) {
      for (let i of premio_fixo) {
        campos_premio[i] = true;
      }
    }
    let siglas = tipojogos.map(value => value.sigla).join(' ');

    let aux = [];
    for (let c of campos) {
      let v = new Object({ ...c });
      v.valor = v.valor.split('©')[0];
      aux.push(v);
    }
    if (tipojogo?.isntDividido) {
      aux.map(c => betStates.games_list.push(c.valor));
    } else if (tipojogo.isGrupo) {
      if (tipojogo.caracter_max_form == tipojogo.divisor) {
        aux.map(c => betStates.games_list.push(c.valor));
      } else if (tipojogo.gera_varios) {
        let str = '';
        aux.map(c => {
          str += c.valor;
        });
        betStates.games_list.push(camposJogos(str, tipojogo));
      } else {
        let str = '';
        aux.map(c => {
          str += c.valor;
        });
        betStates.games_list.push(camposJogos(str, tipojogo));
      }
    } else {
      if (tipojogo.gera_varios) {
        let str = '';
        aux.map(c => {
          str += c.valor;
        });
        betStates.games_list.push(camposJogos(str, tipojogo));
      } else {
        aux.map(c => betStates.games_list.push(c.valor));
      }
    }
    return validaJogo({
      ...betStates,
      tipojogos,
      tipojogo,
      siglas,
      campos: [],
      campos_premio,
      valor2: handleText.moneyBrToUs(valor),
    });
  }

  function deleteGameField(index) {
    let game_index = index;
    betStates.games_list.splice(game_index, 1);
    return validaJogo({
      ...betStates,
      games_list: [...betStates.games_list],
      disableInput: false,
    });
  }

  async function saveBet(forma_pagamento) {
    try {
      let { jogos_salvo, selectedDate, extracoes } = betStates;

      let horario = [];
      extracoes.map(ext => {
        horario.push(ext.cod);
      });
      let aposta = {
        forma_pagamento,
        horario,
        data: handleText.dateBRToUS(selectedDate),
        device_model: '',
        data_celular: new Date(),
        posicao: betStates.posicao,
        versao: '',
        os: 'Mac-OS',
        cotada: false,
        jogos: [],
      };
      aposta.jogos = jogos_salvo.jogos.map(jogo => {
        return {
          dados: jogo.campos.map(campo => campo.valor),
          premios: Object.keys(jogo.premios).filter(
            premio => jogo.premios[premio]
          ),
          siglas: jogo.tipojogos.map(tipo => tipo.sigla),
          ids: jogo.tipojogos.map(tipo => tipo.id),
          valor: jogo.valor,
        };
      });
      let resp = await gameRequests.aposta(aposta);
      console.log(resp);
      changeBetState({
        aposta: {},
        gameAlreadyEnd: true,
        jogos_salvo: {
          jogos: [],
          total: 0,
        },
        valor2: '',
        valores: [],
        isFlex: false,
        valor: '',
        gameCode: '',
        puleBarDate: null,
        tipojogos: [],
        tipojogo: {},
      });
      return resp;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  function repetBets(jogos) {
    let gamesTypes = betStates.indexGamesType;
    for (let j of jogos) {
      let jogo = {
        valor: '',
        tipojogo: null,
        tipojogos: [],
        campos: [],
        campos_premio: {},
      };

      jogo.valor = j.valor;
      jogo.tipojogos = j.ids.map(i => gamesTypes[i]);
      jogo.tipojogo = jogo.tipojogos[jogo.tipojogos.length - 1];
      jogo.campos = j.dados.map(d => ({ valor: d }));

      for (let p of j.premios) {
        jogo.campos_premio[p] = true;
      }
      let res = ajustaJogo({ ...betStates, tipojogos: jogo.tipojogos }, jogo);
      return validaJogo(res);
    }
  }

  return (
    <Provider
      value={{
        betStates,
        setBetStates,
        changeBetState,
        //Game actions
        resetGames,
        prepareGame,
        changeOneValueInput,
        handleAwardButton,
        changeValueToPay,
        saveGame,
        changeAwardValue,
        removeSavedGame,
        prepareToEditBet,
        deleteGameField,
        saveBet,
        repetBets,
        jogos_cache,
        setJogosCache,
        shouldJBLimitUpdate,
        setShouldJBLimitUpdate,
        limparPremios,
      }}
    >
      {children}
    </Provider>
  );
};

function verificaGruposInvalidos(valor, tipojogo) {
  let bool = false;
  let divisor = tipojogo.divisor;
  valor = valor.split('');
  let aux = [];
  valor.map((n, i) => {
    let corte = i + 1;
    if (corte % divisor == 0) {
      let str = valor.slice(corte - divisor, corte).join('');
      aux.push(str);
    }
  });
  aux.map(numero =>
    Number(numero) > 25 || Number(numero) < 1 ? (bool = true) : false
  );
  return bool;
}

function camposJogos(valor, tipojogo) {
  let divisor = tipojogo.divisor;
  valor = valor.split('');
  let aux = [];
  valor.map((n, i) => {
    let corte = i + 1;
    if (corte % divisor == 0) {
      let str = valor.slice(corte - divisor, corte).join('');
      aux.push(str);
    }
  });
  return aux.join('-');
}

let ajustaJogo = (state, jogo) => {
  let { valor, tipojogo, campos, campos_premio } = jogo;

  valor = typeof valor == 'number' ? valor : handleText.moneyBrToUs(valor);

  if (state.tipojogo?.gera_varios) {
    let jogos = [];
    state.games_list.map(game => {
      let _campo = [];
      let games = game.split('-');
      games.map(g => {
        _campo.push({
          valor: g,
          valor2: '',
          removivel: false,
        });
      });

      let _valor = valor / state.games_list.length;
      jogos.push(
        copy({
          tipojogo,
          valor:
            typeof _valor == 'number' ? _valor : handleText.moneyBrToUs(_valor),
          premios: campos_premio,
          tipojogos: state.tipojogos,
          campos: _campo,
        })
      );
    });

    if (state.gameToEdit) {
      state.jogos_salvo.jogos = [
        ...filterLista(state.jogos_salvo.jogos, state.gameToEdit.index),
        ...jogos,
      ];
    } else {
      state.jogos_salvo.jogos = [...state.jogos_salvo.jogos, ...jogos];
    }
    state.jogos_salvo.total = 0;
    state.jogos_salvo.jogos.map(j => (state.jogos_salvo.total += j.valor));
  } else {
    let jogos = [];
    if (tipojogo?.form_unico == '1') {
      state.valores.map(v => {
        let valor = handleText.moneyBrToUs(v.valor);
        if (valor)
          jogos.push(
            copy({
              tipojogo: v.tipojogo,
              tipojogos: [v.tipojogo],
              valor: valor,
              premios: campos_premio,
              campos,
            })
          );
      });
    } else {
      jogos.push(
        copy({
          tipojogo,
          valor:
            typeof valor == 'number' ? valor : handleText.moneyBrToUs(valor),
          premios: campos_premio,
          tipojogos: state.tipojogos,
          campos,
        })
      );
    }
    if (state.gameToEdit) {
      state.jogos_salvo.jogos = [
        ...filterLista(state.jogos_salvo.jogos, state.gameToEdit.index),
        ...jogos,
      ];
    } else {
      state.jogos_salvo.jogos = [...state.jogos_salvo.jogos, ...jogos];
    }
    state.jogos_salvo.total = 0;
    state.jogos_salvo.jogos.map(j => (state.jogos_salvo.total += j.valor));
  }
  return { ...state, jogos_salvo: { ...state.jogos_salvo }, gameToEdit: false };
};

let isValido = state => {
  if (state?.tipojogo?.tipo == 'I' || state?.tipojogo?.tipo == 'S') {
    return validaOneInput(state);
  } else {
    return true;
  }
};

let validaOneInput = state => {
  let {
    caracter_min_form,
    caracter_max_form,
    req_premio_min,
    max_aposta,
    req_premio_max,
  } = state.tipojogo;
  if (
    state.games_list.length < Number(state.tipojogo.qtd_jogos_min) ||
    state.games_list.length > Number(state.tipojogo.qtd_jogosv2)
  )
    return false;
  for (let game of state.games_list) {
    game = game.split('-').join('');
    if (
      game.length < Number(caracter_min_form) ||
      game.length > Number(caracter_max_form)
    )
      return false;
  }
  let premios_ativos = [];
  for (let i in state.campos_premio) {
    if (state.campos_premio[i]) premios_ativos.push(i);
  }

  if (
    premios_ativos.length < Number(req_premio_min) ||
    premios_ativos.length > Number(req_premio_max)
  )
    return false;

  let valor = (state.valor || '').split('.').join('').split(',').join('.');
  if (max_aposta > 0 || max_aposta != '0') {
    if (Number(valor) > Number(max_aposta) || !Number(valor)) return false;
  }
  return true;
};

function verificaCotadas(betStates) {
  let _cotadas = betStates.specialBets;
  let { campos, campos_premio } = betStates;
  let premios = [];
  let extracoes = [];
  for (let i in campos_premio) {
    if (campos_premio[i]) premios.push(i);
  }
  for (let ext of betStates.extracoes) {
    extracoes.push(ext);
  }

  let hashs = {};
  for (let cotada of _cotadas) {
    for (let premio of cotada.premios) {
      for (let horario of cotada.extracoes) {
        hashs[`${cotada.dado}-${cotada.tipoJogo.id}-${premio}-${horario.cod}`] =
          cotada.cotacao;
      }
    }
  }
  for (let tipojogo of betStates.tipojogos) {
    let { caracter_min_form } = tipojogo;
    caracter_min_form = Number(caracter_min_form);
    for (let campo of campos) {
      let valor_campo = campo.valor.substr(-caracter_min_form);
      for (let premio of premios) {
        for (let extracao of extracoes) {
          let hash = `${valor_campo}-${tipojogo.id}-${premio}-${extracao.cod}`;
          if (hashs[hash] && !campo.cotada) {
            campo.cotada = true;
            campo.valor = campo.valor + '©';
          }
        }
      }
    }
  }
}

function copy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

function filterLista(lista = [], index) {
  return lista.filter((e, i) => i != index);
}
