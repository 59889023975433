import { useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { FutQuerys } from '../../api/query/fut';
import { UserQueries } from '../../api/query/user';
import LoadingBalls from '../../components/loading/loading-balls';
import TableHome from '../../components/table/table-home';
import { useUsuarioContext } from '../../context/context-user';
import { isDev } from '../../utils';
import {Link } from 'react-router-dom'


export default function Home() {
  const { setReference, getReference, setUsuario } = useUsuarioContext();
  const router = useNavigate();
  const [date, setDate] = useState(null);
  const [busca, setBusca] = useState('');
  const [busca_bounce] = useDebounce(busca, isDev ? 500 : 1000);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);

  const location = useLocation();

  const [getEventosQuery] = useLazyQuery(FutQuerys.getEventosFutebol);
  const [queryUsuario] = useLazyQuery(FutQuerys.consultaReferencia);
  const [queryUsuarioLogado] = useLazyQuery(UserQueries.atualizandoUsuarioSite);

  useEffect(() => {
    if (Object.keys(location.search).length) {
      let params = new URLSearchParams(location.search);
      let ref_id = params.get('referrer_id');
      let qdate = params.get('date');
      if (ref_id && !qdate) {
        replaceRoute(ref_id);
      } else if (!!ref_id && !!qdate) {
        resolveReference(ref_id);
        setDate(qdate);
      } else if (!!!ref_id && !!qdate) {
        setDate(qdate);
      }
    } else {
      replaceRoute();
    }
  }, [location.search]);

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (!!date || !!busca_bounce) getEventosData(date, busca_bounce);
  }, [date, busca_bounce]);

  async function getUserData() {
    try {
      let response = await queryUsuarioLogado({ fetchPolicy: 'network-only' });
      if (!!response?.data?.atualizandoUsuarioSite?._id) {
        let u = response?.data?.atualizandoUsuarioSite;
        setUsuario(_ => {
          return { ..._, ...u };
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getEventosData(data, busca = '') {
    if (loading) return;
    setLoading(true);
    try {
      let resp = await getEventosQuery({ variables: { busca, data } });
      if (process.env.NODE_ENV == 'development') console.log(data?.getEventosFutebol);
      setData(resp.data.getEventosFutebol || []);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  function replaceRoute(ref_id) {
    let today = dayjs().format('YYYY-MM-DD');
    let path = window.location.pathname;
    let qs = {};
    if (!!ref_id) qs['referrer_id'] = ref_id;
    if (!!today) qs['date'] = today;
    qs = Object.keys(qs).map(item => `${item}=${qs[item]}`);
    path = `${path}?${qs.join('&')}`;
    router(path, { replace: true });
  }

  async function resolveReference(ref_id) {
    try {
      let get_user = await queryUsuario({
        variables: { codigo: ref_id },
        nextFetchPolicy: 'no-cache',
      });
      if (!!get_user?.data?.futebolsite_consultaReferencia) {
        let reference = JSON.parse(
          get_user.data.futebolsite_consultaReferencia
        );
        let get_reference = getReference();
        if (reference?._id != get_reference?._id) setReference(reference);
        else if (reference?._id && !get_reference) setReference(reference);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function renderDates() {
    const dates = [];
    for (let i = 0; i < 3; i++) {
      let label = '';
      let value = dayjs().add(i, 'd').format('YYYY-MM-DD');
      if (i == 0) label = 'HOJE';
      if (i == 1) label = dayjs().add(i, 'd').format('dddd').toUpperCase();
      if (i == 2) label = dayjs().add(i, 'd').format('dddd').toUpperCase();
      label = label.split('-FEIRA').join('');
      dates.push({ label, value });
    }
    return (
      <div className="d-flex flex-row gap-2">
        {dates.map(item => {
          return (
            <Button
              size="sm"
              key={item.value}
              className={`${
                item.value == date ? 'bg-primary-sm' : 'bg-dark'
              } bg-gradient text-white ${loading ? 'disabled' : ''}`}
              onClick={() => {
                if (loading) return;
                let route = window.location.pathname;
                let query = `?date=${item.value}`;
                let _ = route + query;
                router(_, { replace: true });
              }}
            >
              {item.label}
            </Button>
          );
        })}
      </div>
    );
  }

  return (
    <Container className="px-0 d-grid ">
      <div className="btn-group btn-group-lg    py-1 px-1 ">
        <Link
          style={{ fontSize: '0.8rem' }}
          to="/esportes/resultado"
          className="btn btn-primary border py-2  "
        >
          Resultados
        </Link>
        <Link
          style={{ fontSize: '0.8rem' }}
          to="#"
          className="btn btn-primary border  py-2"
        >
          Aprenda
        </Link>
        <Link
          style={{ fontSize: '0.8rem' }}
          to="/esportes/regulamento"
          className="btn btn-primary  border py-2"
        >
          Regulamento
        </Link>
      </div>

      <div className="  d-block    d-grid pb-3 pt-1 gap-1   ">
        <div className="d-flex flex-column gap-1 mx-2">
          {renderDates()}
          <Form.Control
            style={{ fontSize: 16 }}
            className="form-control-sm"
            placeholder="Buscar partida/liga"
            name={'busca'}
            onChange={env => setBusca(env.target.value)}
          />
        </div>
        {loading ? <LoadingBalls /> : <TableHome {...{ data, date }} />}
      </div>
    </Container>
  );
}
