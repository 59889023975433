import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useLocation } from 'react-router-dom';
import { FutQuerys } from '../../api/query/fut';
import { gameRequests } from '../../requests/gameRequests';
import { handleIcons } from '../../utils/handle-icon';
import { handleText } from '../../utils/handle-text';
import resolverTexts from '../../utils/resolver-texts';

function Row({ children }) {
  return (
    <div
      style={{ fontSize: '.7rem' }}
      className="d-flex justify-content-between align-items-center"
    >
      {children}
    </div>
  );
}

export default function CompartilharCodigoBilhete() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [consultaBilheteQuery] = useLazyQuery(FutQuerys.consultaBilheteFutebol);
  const [bilhetes, setBilhetes] = useState([]);
  const isJb = location.pathname.search('jb') == 1;

  useEffect(() => {
    if (Object.keys(location.search).length) {
      let params = new URLSearchParams(location.search);
      let codigos = params.get('codigos');
      let futebol = params.get('futebol') == 'true';
      if (!!codigos) {
        getBilhete(futebol, codigos);
      }
    }
  }, [location.search]);

  async function getBilhete(futebol, codigo) {
    if (loading) return;
    setLoading(true);
    try {
      if (!isJb) {
        let { data, error } = await consultaBilheteQuery({
          variables: { codigo },
          fetchPolicy: 'no-cache',
        });
        
        if (error) throw error;
        if (!!data?.futebolsite_consultaBilheteFutebol) {
          let bilhete_json = JSON.parse(
            data.futebolsite_consultaBilheteFutebol
          );
          setBilhetes([
            {
              ...bilhete_json,
              copiado: false,
            },
          ]);
        }
      } else {
        let data = await gameRequests.getBilhetesJB(
          codigo.split(' ').join(',')
        );
        setBilhetes(
          data.map(jogo => ({
            ...jogo,
            copiado: false,
          }))
        );
      }
    } catch (error) {
      
      setBilhetes(null);
      alert(error);
    }
    setLoading(false);
  }

  function dataMovimento(data) {
    return data.split('T')[0].split('-').reverse().join('/');
  }

  return (
    <div className="container d-grid py-3 ">
      <div className="row justify-content-center gap-2">
        {bilhetes?.map(bilhete => (
          <div key={bilhete._id} className=" col-12 col-md-8 ">
            <div className="card card-body ">
              {bilhete?.status == 'AGUARDANDO VALIDACAO' &&
                bilhete.forma_pagamento == 'COLABORADOR' && (
                  <div className="bg-primary  py-3 my-2 d-flex  rounded flex-column justify-content-center align-items-center">
                    <p className="  text-white text-center  ">
                      <b style={{ color: 'yellow' }}>ATENÇÃO!</b>
                      <br />
                      Esse bilhete precisa ser validado em um colaborador com o
                      código abaixo: <br />
                      <b className="fs-5">{bilhete.cod}</b>
                    </p>
                    <div className="d-grid gap-2 col mx-auto">
                      <Button
                        target="_blank"
                        href={`https://wa.me/?text=${bilhete.cod}`}
                        variant="primary-main"
                      >
                        {'Compartilhar pelo whatsapp '}
                        <handleIcons.Whatsapp size={25} />
                      </Button>

                      <CopyToClipboard
                        text={bilhete.cod}
                        onCopy={() => {
                          bilhete.copiado = true;
                          setBilhetes(prev => [...prev]);
                        }}
                      >
                        <Button
                          variant={bilhete.copiado ? 'success' : 'primary-main'}
                          className="btn-primary-main "
                        >
                          {bilhete.copiado ? (
                            <>
                              {`Código copiado `}
                              <handleIcons.Check size={25} />
                            </>
                          ) : (
                            <>
                              {`Copiar código `}
                              <handleIcons.Copy size={25} />
                            </>
                          )}
                        </Button>
                      </CopyToClipboard>
                    </div>
                  </div>
                )}
              <hr />

              <h5 className="text-center">{resolverTexts(bilhete?.status)}</h5>
              {/* <Row>
                <div>Forma de pagamento</div>
                <div className="text-end fw-semibold">
                  {bilhete.forma_pagamento}
                </div>
              </Row> */}

              <Row>
                <div>Jogos</div>
                <div className="text-end fw-semibold">
                  {bilhete?.jogos?.length || bilhete?.jogos_futebol?.length}
                </div>
              </Row>
              {bilhete.futebol && (
                <Row>
                  <div>Cotação</div>
                  <div className="text-end fw-semibold">
                    {bilhete.cotacao_bilhete.toFixed(1)}
                  </div>
                </Row>
              )}
              {!bilhete.futebol && bilhete?.extracao && (
                <Row>
                  <div>Extração</div>
                  <div className="text-end fw-semibold">
                    {bilhete.extracao.descricao}
                  </div>
                </Row>
              )}
              {!bilhete.futebol && bilhete?.data_movimento && (
                <Row>
                  <div>Data</div>
                  <div className="text-end fw-semibold">
                    {dataMovimento(bilhete.data_movimento)}
                  </div>
                </Row>
              )}

              <Row>
                <div>Valor total</div>
                <div className="text-end fw-semibold">
                  {handleText.asMoney(bilhete.valor_bruto)}
                </div>
              </Row>
              {bilhete?.possivel_premio && (
                <Row>
                  <div>Possível retorno</div>
                  <div className="text-end fw-semibold">
                    {handleText.asMoney(bilhete.possivel_premio)}
                  </div>
                </Row>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
